<template>
  <div class="page-common page-vip-card">
    <el-tabs v-model="cuttentTab">
      <el-tab-pane name="card1" label="会员卡">
        <VipCardList :card-type="0" />
      </el-tab-pane>
      <el-tab-pane lazy name="card2" label="权益次卡">
        <VipCardList :card-type="1" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import VipCardList from '@/views/operation/vip-card/vipcard-list.vue'

export default {
  name: 'OpenAccount',
  components: { VipCardList },
  data() {
    return {
      cuttentTab: 'card1'
    }
  }
}
</script>
<style lang="scss">
.page-vip-card {
  position: relative;
}
</style>
