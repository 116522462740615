<template>
  <div>
    <el-table
      ref="table"
      size="medium"
      element-loading-spinner="el-icon-loading"
      v-loading="loading"
      :class="{'sticky-header': stickyHeader}"
      :data="tableData"
      :stripe="stripe"
      :border="border"
      :height="height"
      :row-class-name="rowClassName"
      @selection-change="changeSelection"
      @sort-change="sortChange"
    >
      <slot />
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        layout="sizes, total, prev, pager, next, jumper"
        :current-page="pagination.page"
        :total="total"
        :page-size="pagination.limit"
        :page-sizes="[10, 20, 30, 40, 50]"
        :disabled="paginationOff"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonTable',
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    promise: Function,
    mapData: {
      type: Function,
      default: (res) => res.items
    },
    mapTotal: {
      type: Function,
      default: (res) => res.total_count
    },
    sortChange: {
      type: Function,
      default: () => null
    },
    changeSelection: {
      type: Function,
      default: () => null
    },
    remainPage: Boolean,
    height: [Number, String],
    paginationOff: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    stripe: {
      type: Boolean,
      default: true
    },
    stickyHeader: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: [Function, String]
    },
    immediate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableData: [],
      pagination: {
        page: 1,
        limit: this.params.limit || 20
      },
      loading: false,
      total: 1000
    }
  },
  computed: {
    cursor() {
      return this.pagination.page
    }
  },
  methods: {
    handleSizeChange(limit) {
      // eslint-disable-next-line vue/no-mutating-props
      this.params.limit = limit
      this.loading = true
      this.pagination.limit = limit
      this.pagination.page = 1
      this.getData()
    },
    handlePageChange(currentPage) {
      this.loading = true
      this.pagination.page = currentPage
      this.getData()
      window.scroll(0, 0)
    },
    search() {
      this.pagination.page = 1
      this.getData()
    },
    getData({ withloading = true } = {}) {
      if (withloading) this.loading = true
      this.promise({
        cursor: this.cursor,
        ...this.pagination,
        ...this.params
      })
        .then((res) => {
          this.loading = false
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = this.mapData(res.data || res) // 兼容一下格式
            this.total = this.mapTotal(res.data || res)
            this.$emit('emit-table-data', {
              data: res.data || res
            })
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    async getRefreshData() {
      try {
        const res = await this.promise({
          cursor: this.cursor,
          ...this.pagination,
          ...this.params
        })
        return {
          tableData: this.mapData(res.data || res),
          total: this.mapTotal(res.data || res)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    reset() {
      this.loading = true
      this.pagination = {
        page: 1,
        limit: this.params.limit || 20
      }
      // window.history.pushState({}, '', location.origin + location.pathname)
      this.getData()
    },
    clearSelection() {
      this.$refs.table.clearSelection()
    }
  },
  mounted() {
    if (this.immediate) {
      this.getData()
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  float: right;
  margin: 20px 0;
}
.el-table.sticky-header {
  overflow: visible;
  .el-table__header-wrapper {
    position: sticky;
    top: 0;
    z-index: 99;
  }
}
</style>
