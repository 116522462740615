import stark from '@/http/stark'

/**
 * @description 课程相关API
 */

// 获取所有课程
export function fetchCourseList(params) {
  return stark.get('/apiv1/admin/course/get_courses', { params })
}
// 创建、更改课程
export function handleCourseCommon(body) {
  return stark.post('/apiv1/admin/course/upsert', body)
}
// 删除课程
export function deleteCourse(course_id = 0) {
  return stark.post('/apiv1/admin/course/delete', { course_id })
}
// 课程ID获取课时列表详情
export function fetchCourseLessonList(params) {
  return stark.get(
    '/apiv1/admin/course/lesson/get_course_sections_and_lessons',
    { params }
  )
}
// 课程课时列表的排序
export function updateCourseLessonList(body) {
  return stark.post(
    '/apiv1/admin/course/lesson/sort_sections_and_lessons',
    body
  )
}

// 课时ID获取课时详情
export function fetchLessonInfo(params) {
  return stark.get('/apiv1/admin/course/lesson/get_lesson', params)
}
// 新增、修改课时
export function createCourseLesson(body) {
  return stark.post('/apiv1/admin/course/lesson/upsert', body)
}
// 获取课时详情
export function fetchLessonById(params) {
  return stark.get('/apiv1/admin/course/lesson/get', { params })
}
// 新增、修改章节
export function createCourseSection(body) {
  return stark.post('/apiv1/admin/course/section/upsert', body)
}
// 章节删除
export function deleteCourseSection(body) {
  return stark.post('/apiv1/admin/course/section/delete', body)
}
// 课时删除
export function deleteCourseLesson(body) {
  return stark.post('/apiv1/admin/course/lesson/delete', body)
}

// ----------------运营相关
export function fetchCourseTabFresh(params) {
  return stark.get('/apiv1/admin/course/fresh_course', { params })
}
export function updateCourseTabFresh(body) {
  return stark.client.put('/apiv1/admin/course/fresh_course', body)
}

export function fetchCourseTabFlow(params) {
  return stark.get('/apiv1/admin/course/recommend_icon', { params })
}
export function updateCourseTabFlow(body) {
  return stark.client.put('/apiv1/admin/course/recommend_icon', body)
}
export function fetchCourseWeekly(params) {
  return stark.get('/apiv1/admin/course/weekly_course', { params })
}
export function updateCourseWeekly(body) {
  return stark.client.put('/apiv1/admin/course/weekly_course', body)
}
export function fetchCourseLabel(params) {
  return stark.get('/apiv1/admin/course/labels', { params })
}
export function updateCourseLabel(body) {
  return stark.client.put('/apiv1/admin/course/labels', body)
}

// 课时审核
export function fetchLessonAuthList(params) {
  return stark.get(
    '/apiv1/admin/course/lesson/get_course_lessons_for_reviewing',
    { params }
  )
}
export function authCourseLesson(body) {
  return stark.post('/apiv1/admin/course/lesson/review', body)
}
