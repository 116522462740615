import { isProd } from "@/utils"

export const rules_card = {
  name: [{ required: true, message: '必填项' }],
  business: [{ required: true, message: '必填项' }],
  right_desc: [{ required: true, message: '必填项' }],
  right_desc_image: [{ required: true, message: '必填项' }]
}

export const getInitCardByType = (type) => {
  const sku = {
    id: 0,
    stock: 0,
    desc: '',
    price: 0,
    purchase_count: 0,
    asset_inscrease_time: 0,
    attach_key: 'default',
    attach_attributes: {
      available_times: 0,
      original_price: 0
    }
  }
  const right = {
    card_template_id: 0,
    resource_type: 'subject',
    resource_id: '581',
    owner_type: '',
    owner_id: ''
  }
  const right_cika = {
    card_template_id: 0,
    resource_type: 'message',
    resource_id: '',
    owner_type: 'subject',
    owner_id: '581'
  }
  const imgsrc = isProd ? '' : 'https://image.xuangubao.cn/FjfOCZZYw8Jw2w4bMh2V_cXjoyVm'
  return {
    name: '',
    small_image: imgsrc,
    mid_image: imgsrc,
    large_image: imgsrc,
    image_text_color: '',
    right_desc: '',
    right_desc_image: imgsrc,
    right_desc_detail: '',
    business: '', // youliao/course
    category: type, // 0 会员卡 1 次卡
    cap_per_user: 0, // 用户限购
    status: 'on', // on/off
    rights: isProd ? [] : [type == 1 ? right_cika : right],
    skus: type == 1 ? [sku] : []
  }
}