<template>
  <SoloBaseSelect
    :searchMethod="searchMethod"
    :keymap="keymap"
    :modelValue="modelValue"
    @update:modelValue="updateEmit"
  />
</template>
<script>
import SoloBaseSelect from '@/components/selectors/solo-selector'
import { getLiveRoomList } from '@/domains/base-baoer'

/**
 * 单选远程搜索框用例 baoer搜直播间
 * @param vModel string number object 若string、number为真需要手动指定options
 * @param searchMethod 编写搜索函数
 * @param keymap 指定keymap
 */

function searchMethod(query, ctx) {
  if (!/\S/.test(query)) return
  ctx.loading.value = true
  const params = Object.assign({ q: query }, this.params)
  getLiveRoomList(params)
    .then((res) => {
      if (res.items && res.items.length) {
        ctx.options.value = res.items.map(({ stream = {} }) => {
          return {
            id: stream && stream.id,
            title: stream && stream.title,
            commodity_id: stream && stream.commodity_id
          }
        })
      }
    })
    .finally(() => {
      ctx.loading.value = false
    })
}

export default {
  name: 'LiveRoomSelect',
  components: { SoloBaseSelect },
  props: {
    modelValue: { type: [String, Number, Object], default: '' },
    params: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      keymap: {
        label: 'title',
        value: 'id'
      }
    }
  },
  methods: {
    searchMethod,
    updateEmit(ev) {
      this.$emit('update:modelValue', ev)
    }
  }
}
</script>