
import VipCardList from '@/views/operation/vip-card/vipcard-list.vue'

export default {
  name: 'OpenAccount',
  components: { VipCardList },
  data() {
    return {
      cuttentTab: 'card1'
    }
  }
}
