import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from '@/config'
import { userStore } from '@/store/modules/user'
import AxiosClient from '../base/AxiosClient'
// import { throwApiErr } from './err'

function requestConfig(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
  const conf: AxiosRequestConfig = {
    ...requestConfig,
    headers: {
      'X-Ivanka-Token': userStore?.getToken,
      ...requestConfig.headers
    }
  }
  return conf
}

function handleError(res) {
  if (res.message == '其他客户端登录了' || res.message == '非法的token') {
    userStore.dispatchLogout()
  }
}

function handleBusinessError(response: AxiosResponse) {
  const responseStructure: { code: number; data: any; message: string } = response.data
  if (response.data.code !== 20000) {
    handleError(response.data)
    const err_data = { ...response.data, url: response.config.url }
    console.log('STARK:', new Error(JSON.stringify(err_data, null, 2)))
    return Promise.reject(err_data)
  }
  return responseStructure.data
}

class STARKServiceClient extends AxiosClient {
  constructor(options: AxiosRequestConfig) {
    super(options)
    this.interceptRequest()
    this.interceptResponse()
  }
  interceptRequest() {
    this.client.interceptors.request.use(requestConfig, Promise.reject)
  }
  interceptResponse() {
    this.client.interceptors.response.use(handleBusinessError, Promise.reject)
  }
}

export default new STARKServiceClient({
  baseURL: config.apiBase.STARK_API
})
