<template>
  <SoloBaseSelect
    :searchMethod="searchMethod"
    :keymap="keymap"
    :modelValue="modelValue"
    @update:modelValue="updateEmit"
  />
</template>
<script>
import SoloBaseSelect from '@/components/selectors/solo-selector'
// 简版搜话题
import { searchSubject } from '@/domains/base-baoer'

/**
 * 单选远程搜索框用例 baoer搜话题
 * @param vModel string number object 若string、number为真需要手动指定options
 * @param searchMethod 编写搜索函数
 * @param keymap 指定keymap
 */

function searchMethod(query, ctx) {
  if (!/\S/.test(query)) return
  ctx.loading.value = true
  const params = Object.assign({ q: query }, this.params)
  searchSubject(params)
    .then((res) => {
      if (res.items && res.items.length) {
        ctx.options.value = res.items.map(({ id, title }) => {
          return {
            id,
            title
          }
        })
      }
    })
    .finally(() => {
      ctx.loading.value = false
    })
}

export default {
  name: 'SubjectSelect',
  components: { SoloBaseSelect },
  props: {
    modelValue: { type: [String, Number], default: '' },
    params: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      keymap: {
        label: 'title',
        value: 'id'
      }
    }
  },
  methods: {
    searchMethod,
    updateEmit(ev) {
      this.$emit('update:modelValue', ev)
    }
  }
}
</script>