<template>
  <div class="vip-card-form">
    <el-dialog
      custom-class="large-modal"
      :title="iscika ? '创建次卡' : '创建会员卡'"
      :close-on-click-modal="false"
      :modelValue="visible"
      @close="$emit('update:visible', false)"
    >
      <el-form
        ref="vipcard"
        label-position="left"
        label-width="120px"
        :rules="rules_card"
        :model="cardBody"
      >
        <el-form-item label="会员卡名称" prop="name">
          <el-input :maxlength="7" type="text" v-model.trim="cardBody.name"></el-input>
        </el-form-item>
        <el-form-item :label="iscika ? '次卡权益' : '会员卡权益'">
          <template v-if="cardBody.rights && cardBody.rights.length">
            <el-tag
              closable
              type="success"
              :disable-transitions="true"
              v-for="(right, idx) in cardBody.rights"
              :key="idx"
              @close="delRight(idx)"
            >
              <span v-if="iscika">{{right.owner_type}} {{right.name || right.owner_id}}</span>
              <span v-else>{{right.resource_type}} {{right.name || right.resource_id}}</span>
            </el-tag>
          </template>
          <div>
            <el-radio-group class="mr-4" v-if="!iscika" v-model="bindRight.type">
              <el-radio label="subject">专栏</el-radio>
              <el-radio label="course">课程</el-radio>
              <el-radio label="streamer">直播间</el-radio>
            </el-radio-group>
            <SubjectSelect
              v-show="bindRight.type == 'subject'"
              style="width: 320px"
              placeholder="选择专栏"
              @get-option="getOption"
              v-model="bindRight.subject"
            />
            <LiveRoomSelect
              v-show="bindRight.type == 'streamer'"
              style="width: 320px"
              placeholder="选择直播间"
              @get-option="getOption"
              v-model="bindRight.streamer"
            />
            <WitsCourseSelect
              v-show="bindRight.type == 'course'"
              style="width: 320px"
              placeholder="选择课程"
              @get-option="getOption"
              v-model="bindRight.course"
            />&nbsp;
            <el-button
              size="mini"
              type="primary"
              @click="addRight"
              :disabled="!bindRight[bindRight.type]"
            >添加{{bindRightMap[bindRight.type]}}</el-button>
          </div>
        </el-form-item>

        <!-- 非次卡订阅项配置 -->
        <el-form-item v-if="!iscika" label="时效+价格">
          <div class="skus-head">
            <span>选项名称</span>
            <span>实际支付价</span>
            <span>原价</span>
            <span>天数</span>
          </div>
          <div class="skus-line" v-for="(sku, idx) in cardBody.skus" :key="idx">
            <span>{{sku.desc}}</span>
            <span>{{$filters.toYuan(sku.price)}}</span>
            <span>{{$filters.toYuan(sku.attach_attributes.original_price)}}</span>
            <span>
              <span>{{sku.asset_inscrease_time}}</span>&nbsp;
              <i class="el-icon-edit" v-if="sku.id > 0" @click="editSku(sku)"></i>&nbsp;
              <i class="el-icon-delete" style="color: #f33" @click="delSku(idx)"></i>
            </span>
          </div>
          <template v-if="addingShow">
            <div class="skus-line-add" style="margin-top: 4px">
              <div>
                <span>sku名称</span>
                <el-input type="text" placeholder="请输入" v-model.trim="addingSku.desc"></el-input>
              </div>
              <div>
                <span class="mark">实际价</span>
                <FenInput placeholder="实际价" v-model.number="addingSku.price" />
                <!-- <el-input type="number" placeholder="单位元" v-model.number="addingSku.price"></el-input> -->
              </div>
              <div>
                <span class="mark">原价</span>
                <FenInput
                  placeholder="原价"
                  v-model.number="addingSku.attach_attributes.original_price"
                />
                <!-- <el-input
                  type="number"
                  placeholder="原价"
                  v-model.number="addingSku.attach_attributes.original_price"
                ></el-input>-->
              </div>
              <div>
                <span>天数（36500表无限期）</span>
                <el-input
                  type="number"
                  placeholder="天数"
                  v-model.number="addingSku.asset_inscrease_time"
                ></el-input>
              </div>
            </div>
            <el-button size="mini" type="info" @click="addingShow = false">取消</el-button>&nbsp;
            <el-button size="mini" type="success" :disabled="addingSkuCheck" @click="confirmSku">确认</el-button>
          </template>
          <el-button v-else size="mini" type="primary" @click="addSku">添加订阅项</el-button>
        </el-form-item>

        <!-- 次卡需要显示的配置项 -->
        <template v-if="iscika">
          <el-form-item v-if="iscika" label="每人限购" prop="cap_per_user">
            <el-input
              type="number"
              style="width: 300px"
              :min="0"
              v-model.number="cardBody.cap_per_user"
            ></el-input>
            <span>&nbsp;&nbsp;0表不限购</span>
          </el-form-item>
          <el-form-item label="可解锁篇数" v-if="iscika">
            <el-input
              :min="1"
              style="width: 300px"
              type="number"
              v-model.number="addingSku.attach_attributes.available_times"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="iscika" label="价格+时效">
            <div class="skus-line-add">
              <div>
                <span>sku名称</span>
                <el-input type="text" placeholder="请输入" v-model.trim="addingSku.desc"></el-input>
              </div>
              <div>
                <span class="mark">实际价</span>
                <FenInput placeholder="单位元" v-model.number="addingSku.price" />
              </div>
              <div>
                <span class="mark">原价</span>
                <FenInput
                  placeholder="单位元"
                  v-model.number="addingSku.attach_attributes.original_price"
                />
              </div>
              <div>
                <span>有效期（36500表无限期）</span>
                <el-input
                  type="number"
                  placeholder="天数"
                  v-model.number="addingSku.asset_inscrease_time"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="卡面配色">
            <el-input type="text" style="width: 300px" v-model.trim="cardBody.image_text_color"></el-input>
            <span>&nbsp;&nbsp;例: #ABCDEF</span>
          </el-form-item>
        </template>

        <el-form-item label="业务" prop="business">
          <el-select placeholder="请选择" v-model="cardBody.business">
            <el-option label="课程" value="course"></el-option>
            <el-option label="有料" value="youliao"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权益说明" prop="right_desc">
          <el-input type="textarea" autosize v-model.trim="cardBody.right_desc"></el-input>
        </el-form-item>
        <el-form-item label="说明图片" prop="right_desc_image">
          <UploadQiniu v-model="cardBody.right_desc_image" />
        </el-form-item>
        <el-form-item label="卡面图片">
          <details>
            <summary>
              <span class="font-bold">配置大中小图片</span>
            </summary>
            <span>详情图</span>
            <UploadQiniu v-model="cardBody.right_desc_detail" />
            <span>small_image</span>
            <UploadQiniu v-model="cardBody.small_image" />
            <span>middle_image</span>
            <UploadQiniu v-model="cardBody.mid_image" />
            <span>large_image</span>
            <UploadQiniu v-model="cardBody.large_image" />
          </details>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="loading" :loading="loading" @click="cardConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import { unref } from 'vue'
import UploadQiniu from '@/components/base/upload-qiniu'
import SubjectSelect from '@/components/use-case/subject-solo-select'
import WitsCourseSelect from '@/components/use-case/wits-course-select'
import LiveRoomSelect from '@/components/use-case/liveroom-select'
import { rules_card } from './constant'

const defaultSku = {
  id: 0,
  desc: '',
  price: 0,
  stock: 0,
  asset_inscrease_time: 0,
  attach_key: 'default',
  attach_attributes: {
    available_times: 0,
    original_price: 0
  }
}

export default {
  name: 'VipCardForm',
  emits: ['update:visible', 'submit'],
  components: { UploadQiniu, SubjectSelect, WitsCourseSelect, LiveRoomSelect },
  props: {
    visible: { type: Boolean, default: false },
    cardData: { type: Object, required: true },
    cardType: { type: Number, default: 0 },
    loading: { type: Boolean, default: false }
  },
  setup(props) {
    return {
      rules_card,
      iscika: props.cardType == 1
    }
  },
  data() {
    this.bindRightMap = {
      subject: '专栏',
      course: '课程',
      streamer: '直播间'
    }
    return {
      cardBody: cloneDeep(unref(this.cardData)),
      rightType: '',
      bindRight: {
        type: 'subject',
        name: '',
        subject: '',
        course: '',
        streamer: ''
      },
      addingSku: cloneDeep(defaultSku),
      addingShow: false
    }
  },
  watch: {
    visible(vi) {
      if (vi) {
        const temp = cloneDeep(unref(this.cardData))
        this.cardBody = temp
        if (this.iscika && this.cardData.skus[0]) {
          this.addingSku = cloneDeep(unref(this.cardData.skus[0]))
        } else {
          this.addingSku = cloneDeep(defaultSku)
        }
      }
    }
  },
  computed: {
    addingSkuCheck() {
      const {
        desc,
        price,
        asset_inscrease_time,
        attach_attributes: { original_price }
      } = this.addingSku
      return !desc || price <= 0 || asset_inscrease_time <= 0 || original_price <= 0
    }
  },
  mounted() {
    console.log(this)
  },
  methods: {
    getOption(op) {
      // console.log(op)
      this.bindRight.name = op.name || op.title
    },
    addRight() {
      const resource_id = String(this.bindRight[this.bindRight.type])
      console.log('addRight', this.bindRight.type, resource_id)
      let right = {
        id: 0,
        resource_type: this.bindRight.type,
        resource_id,
        owner_type: '',
        owner_id: '',
        name: this.bindRight.name
      }
      if (this.iscika) {
        right = {
          id: 0,
          resource_type: 'message',
          resource_id: '',
          owner_type: 'subject',
          owner_id: resource_id
        }
      }
      if (!this.cardBody.rights) {
        this.cardBody.rights = [right]
      } else {
        this.cardBody.rights.push(right)
      }
      this.bindRight[this.bindRight.type] = ''
    },
    delRight(idx) {
      this.cardBody.rights.splice(idx, 1)
    },
    addSku() {
      if (this.addingShow && this.addingSku.id) return
      this.addingShow = true
      this.addingSku = cloneDeep(defaultSku)
    },
    editSku(sku) {
      if (this.addingShow && !this.addingSku.id) return
      this.addingShow = true
      this.addingSku = cloneDeep(sku)
    },
    delSku(idx) {
      this.cardBody.skus.splice(idx, 1)
    },
    confirmSku() {
      const addingSku = this.addingSku
      console.log(addingSku)
      if (!addingSku.id) {
        this.cardBody.skus.push(addingSku)
      } else {
        const index = this.cardBody.skus.findIndex((sku) => {
          return sku.id == addingSku.id
        })
        this.cardBody.skus[index] = addingSku
      }
      this.addingSku = {
        desc: '',
        price: 0,
        stock: 0,
        asset_inscrease_time: 0,
        attach_key: 'default',
        attach_attributes: {
          available_times: 0,
          original_price: 0
        }
      }
      this.addingShow = false
    },
    cardConfirm() {
      this.$refs.vipcard.validate((ok) => {
        if (!ok) return
        console.log(this.cardBody)
        if (this.iscika) {
          if (!checkCikaSku(this.addingSku, this)) return
          this.cardBody.skus = [cloneDeep(this.addingSku)]
        }
        const { rights, skus } = this.cardBody
        if (!rights.length) return this.$message.error('请配置会员卡权益')
        if (!skus.length) return this.$message.error('请配置会员卡订阅项')
        this.$emit('submit', cloneDeep(this.cardBody))
      })
    }
  }
}

function checkCikaSku(addingSku, ctx) {
  const {
    price,
    asset_inscrease_time,
    attach_attributes: { available_times, original_price }
  } = addingSku
  if (price <= 0 || original_price <= 0) {
    ctx.$message.error('实际价格及原价必填')
    return
  }
  if (asset_inscrease_time <= 0) {
    ctx.$message.error('有效期必填，无限期请填36500')
    return
  }
  if (available_times <= 0) {
    ctx.$message.error('可解锁篇数必填')
    return
  }
  return true
}
</script>
<style lang="scss">
.vip-card-form {
  .el-tag {
    font-weight: 600;
    margin: 0 6px 6px 0;
  }
  .skus-head,
  .skus-line {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: center;
    > span {
      flex-shrink: 0;
      width: 25%;
    }
    i[class^='el-icon'] {
      cursor: pointer;
      font-weight: 600;
    }
  }
  .skus-head {
    background: #eee;
  }
  .skus-line-add {
    align-items: center;
    background: #f4f4f4;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    padding: 0 10px 10px;
    > div {
      margin-right: 2%;
      width: 23%;
    }
  }
  .vip-card-img {
    height: auto;
    max-width: 160px;
  }
}
</style>
