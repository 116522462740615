
import uniqBy from 'lodash/uniqBy'
import { PropType, computed, ref } from 'vue'

/**
 * @description 支持远程搜索(初始化)的单选框
 */

const isObject = (val) => Object.prototype.toString.call(val) === '[object Object]'

export default {
  name: 'SoloBaseSelect',
  emits: ['update:modelValue', 'get-option'],
  props: {
    modelValue: {
      type: [String, Number, Object] as PropType<string | number | {}>
    },
    searchMethod: {
      type: Function,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    },
    placeholder: {
      type: String,
      default: '关键词搜索'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    options: {
      type: [Array, Object] as PropType<[] | {}>
    },
    keymap: {
      type: Object as PropType<{ label: string; value: string }>,
      default: () => ({
        label: 'label',
        value: 'value'
      })
    }
  },
  setup(props, ctx) {
    const value = computed(() => {
      if (isObject(props.modelValue)) return (props.modelValue as {})[props.keymap.value] || ''
      return props.modelValue
    })
    const loading = ref<boolean>(false)
    const refRemoteOptions = ref<any[]>([])
    const defaultOptions = computed<any[]>(() => {
      const temp: any[] = []
      if (isObject(props.options)) temp.push(props.options)
      if (Array.isArray(props.options)) temp.push(...props.options)
      if (isObject(props.modelValue) && (props.modelValue as any)[props.keymap.value]) {
        temp.push(props.modelValue)
      }
      return temp
    })
    const refRenderOptions = computed(() => {
      return uniqBy([...defaultOptions.value, ...refRemoteOptions.value], props.keymap.value)
    })
    const handleSearchOptions = (query: string) => {
      props.searchMethod(query, { options: refRemoteOptions, loading })
    }
    const onChange = (ev: any) => {
      const option = refRenderOptions.value.find((v) => v[props.keymap.value] == ev)
      if (isObject(props.modelValue)) {
        const empty = {
          [props.keymap.value]: undefined,
          [props.keymap.label]: ''
        }
        ctx.emit('update:modelValue', option || empty)
      } else {
        ctx.emit('update:modelValue', ev)
      }
      ctx.emit('get-option', option)
    }
    return { value, refRenderOptions, handleSearchOptions, loading, onChange }
  }
}
