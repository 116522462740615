<template>
  <div class="page-params">
    <el-select
      clearable
      class="param-item"
      placeholder="搜状态"
      v-model="params.status"
      @change="resetTable"
    >
      <el-option label="生效中" value="on"></el-option>
      <el-option label="已撤回" value="off"></el-option>
    </el-select>
    <el-select
      clearable
      class="param-item"
      placeholder="搜业务"
      v-model="params.business"
      @change="resetTable"
    >
      <el-option label="课程" value="course"></el-option>
      <el-option label="有料" value="youliao"></el-option>
    </el-select>
    <StaffSelect class="param-item" placeholder="搜创建人" v-model="params.creator" />
    <StaffSelect class="param-item" placeholder="搜最后操作人" v-model="params.last_operator" />
    <el-button type="primary" class="param-item" icon="el-icon-search" @click="resetTable">搜索</el-button>
    <el-button
      type="success"
      class="param-item"
      icon="el-icon-plus"
      @click="createVipCard"
    >{{cardType == 0 ? '创建会员卡' : '创建次卡'}}</el-button>
  </div>
  <CommonTable
    ref="table"
    sticky-header
    :params="params"
    :mapData="mapData"
    :mapTotal="mapTotal"
    :promise="fetchVipCardList"
  >
    <el-table-column prop="id" label="ID" width="72" align="center" />
    <el-table-column label="名称" align="center">
      <template #default="{row}">
        <div>{{row.name}}</div>
        <el-tag type="primary" v-if="row.category === 1">权益次卡</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="权益" min-width="240" max-width="400" align="center">
      <template #default="{row}">
        <div class="mark" v-if="row.rights && row.rights.length">
          <span
            v-if="cardType == 0"
          >{{row.rights.map(r => `${r.resource_type}-${r.name || r.resource_id}`).join(' ')}}</span>
          <span v-else>{{row.rights.map(r => `${r.owner_type}-${r.name || r.owner_id}`).join(' ')}}</span>
        </div>
      </template>
    </el-table-column>
    <!-- <el-table-column label="赠送优惠券" align="center">
      <template #default="{row}">
        <div>{{row.attach_attributes}}</div>
      </template>
    </el-table-column>-->
    <el-table-column label="价格" align="center">
      <template #default="{row}">
        <template v-if="skusMap[row.id] && skusMap[row.id].length">
          <div v-for="sku in skusMap[row.id]" :key="sku.id">
            <span>{{calcDays(sku.asset_inscrease_time)}}</span>
            <span>{{$filters.toYuan(sku.price)}}</span>
          </div>
        </template>
      </template>
    </el-table-column>
    <el-table-column label="状态" align="center">
      <template #default="{row}">
        <el-tag type="success" v-if="row.status == 'on'">已发布</el-tag>
        <el-tag type="info" v-if="row.status == 'off'">已撤回</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="创建人" align="center">
      <template #default="{row}">
        <span
          v-if="userinfoMap[row.creator]"
        >{{userinfoMap[row.creator].display_name || userinfoMap[row.creator].email}}</span>
      </template>
    </el-table-column>
    <el-table-column label="最后操作人" align="center">
      <template #default="{row}">
        <span
          v-if="userinfoMap[row.last_operator]"
        >{{userinfoMap[row.last_operator].display_name || userinfoMap[row.last_operator].email}}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" width="156">
      <template #default="{row}">
        <el-button size="mini" @click="editCard(row)">编辑</el-button>
        <el-button
          size="mini"
          type="danger"
          v-if="row.status == 'on'"
          @click="updateStatus(row, 'off')"
        >撤回</el-button>
        <el-button size="mini" type="success" v-else @click="updateStatus(row, 'on')">发布</el-button>
      </template>
    </el-table-column>
  </CommonTable>
  <VipCardForm
    :loading="submiting"
    :card-type="cardType"
    :card-data="editingCard"
    v-model:visible="editingVisible"
    @submit="submitCard"
  />
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import CommonTable from '@/components/base/common-table'
import StaffSelect from '@/components/use-case/staff-solo-select'
import VipCardForm from '@/views/operation/vip-card/vipcard-form'
import { fetchVipCardList, createVipCard, updateVipCard, fetchProductByResource } from '@/domains/base-baoer'
import { unref } from '@vue/reactivity'
import { getInitCardByType } from './constant'

// const cardItem = {
//   id: 1,
//   name: '脱水研报权益卡',
//   image_text_color: '#ee5500',
//   right_desc: '这是脱水研报权益卡的相关描述',
//   right_desc_image: 'https://image.xuangubao.cn/Fv5B1JXarm8E8aMdipa4pD59O6E_',
//   rights: [
//     {
//       id: 1,
//       card_template_id: 1,
//       resource_type: 'subject',
//       resource_id: '581'
//     },
//     {
//       id: 2,
//       card_template_id: 1,
//       resource_type: 'subject',
//       resource_id: '679'
//     }
//   ],
//   business: 'youliao',
//   creator: 120000003985,
//   last_operator: 120000003985,
//   status: 'on',
//   created_at: 1616479510,
//   updated_at: 1616479510
// }

// const body = {
//   create_card_req: {
//     item: {
//       business: '',
//       cap_per_user: 0,
//       category: {},
//       created_at: 0,
//       // creator: 0,
//       id: 0,
//       image_text_color: '',
//       large_image: '',
//       last_operator: 0,
//       mid_image: '',
//       name: '',
//       // order: 0,
//       right_desc: '',
//       right_desc_image: '',
//       rights: [
//         {
//           card_template_id: 0,
//           id: 0,
//           owner_id: '',
//           owner_type: '',
//           resource_id: '',
//           resource_type: ''
//         }
//       ],
//       small_image: '',
//       status: ''
//       // updated_at: 0
//     }
//     // operator_id: 0
//   },
//   upsert_product_req: {
//     // account_id: 0,
//     attach_attributes: '',
//     // resource: {
//     //   attach_key: '',
//     //   resource_id: '',
//     //   resource_type: ''
//     // },
//     // resource_name: '',
//     skus: [
//       {
//         id: 0,
//         desc: '',
//         price: 0,
//         stock: 0,
//         asset_inscrease_time: 0,
//         attach_attributes: '',
//         attach_key: '',
//         purchase_count: 0
//         // account_id: 0,
//         // created_at: 0,
//         // product_id: 0,
//         // updated_at: 0
//       }
//     ]
//     // type:
//   }
// }

function handleSkusForEdit(cardBody) {
  if (!cardBody.skus || !cardBody.skus.length) return
  cardBody.skus.forEach((sku) => {
    sku.asset_inscrease_time = Math.ceil(sku.asset_inscrease_time / 86400)
    if (typeof sku.attach_attributes == 'string') {
      try {
        sku.attach_attributes = JSON.parse(sku.attach_attributes)
      } catch (error) {
        console.log(error)
      }
    } else {
      sku.attach_attributes = {
        available_times: 0,
        original_price: 0
      }
    }
  })
}

function reBuildBody(form, sence = '') {
  // 构建提交数据
  const { attach_attributes, skus, ...item } = form
  if (skus && skus.length && sence !== 'status') {
    skus.forEach((sku) => {
      if (typeof sku.attach_attributes == 'object') {
        sku.attach_attributes = JSON.stringify(sku.attach_attributes)
      } else {
        sku.attach_attributes = ''
      }
      sku.asset_inscrease_time = sku.asset_inscrease_time * 86400
    })
  }
  const card_req = form.id ? { update_card_req: { items: [item] } } : { create_card_req: { item } }
  return {
    ...card_req,
    upsert_product_req: {
      attach_attributes,
      skus
    }
  }
}

function calcDays(daysec) {
  return daysec <= 0 ? '无限期' : `${Math.ceil(daysec / 86400)}天`
}

export default {
  name: 'VipCardList',
  components: { CommonTable, StaffSelect, VipCardForm },
  props: {
    cardType: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    this.fetchVipCardList = fetchVipCardList
    this.mapTotal = (res) => res.total
    return {
      params: {
        creator: '',
        status: '',
        last_operator: '',
        business: '',
        category: this.cardType
      },
      editingVisible: false,
      editingCard: {},
      userinfoMap: {},
      skusMap: {},
      submiting: false
    }
  },
  computed: {},
  methods: {
    calcDays,
    mapData(res) {
      if (!res.items || !res.items.length) return []
      if (res.info_map) this.userinfoMap = res.info_map
      this.initSkusMap(res.items)
      return res.items
    },
    initSkusMap(items) {
      if (!items || !items.length) return
      const temp = {
        resources: items.map((it) => {
          return {
            attach_key: 'default',
            resource_id: String(it.id),
            resource_type: 'card'
          }
        })
      }
      fetchProductByResource(temp)
        .then((res) => {
          if (!res.items || !res.items.length) return
          const tempMap = {}
          res.items.forEach((it) => {
            tempMap[it.resource_id] = it.skus || []
          })
          this.skusMap = tempMap
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    resetTable() {
      this.$refs.table.reset()
    },
    createVipCard() {
      this.editingVisible = true
      const cardBody = getInitCardByType(this.cardType)
      this.editingCard = cardBody
    },
    editCard(row) {
      console.log('edit', row.id)
      const { skusMap } = this
      const {
        id,
        name,
        image_text_color = '',
        small_image = '',
        mid_image = '',
        large_image = '',
        status = '',
        right_desc,
        right_desc_image,
        rights = [],
        business,
        category = unref(this.cardType),
        cap_per_user = 0
      } = row
      const editBody = {
        id,
        name,
        category,
        small_image,
        mid_image,
        large_image,
        image_text_color,
        right_desc,
        right_desc_image,
        rights: cloneDeep(rights),
        business,
        cap_per_user,
        status
      }
      if (skusMap[row.id]) {
        editBody.skus = cloneDeep(skusMap[row.id])
        handleSkusForEdit(editBody)
        this.editingCard = editBody
        this.editingVisible = true
        return
      }
      this.$message.warning('列表相关信息暂未初始化完成，请稍等')
      const temp = {
        attach_key: 'default',
        resource_id: String(row.id),
        resource_type: 'card'
      }
      fetchProductByResource({ resources: [temp] })
        .then((res) => {
          if (res.items && res.items[0]) {
            const skus = res.items[0].skus
            editBody.skus = skus
            handleSkusForEdit(editBody)
            this.editingCard = editBody
            this.editingVisible = true
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    updateStatus(row, tobe) {
      console.log('withdrawn', row.id)
      if (!this.skusMap[row.id]) {
        this.$message.warning('列表相关信息暂未初始化完成，请稍等')
        return
      }
      const body = {
        ...row,
        skus: cloneDeep(this.skusMap[row.id]),
        status: tobe
      }
      const err_msg = tobe == 'on' ? '已发布' : '已撤回'
      updateVipCard(reBuildBody(body, 'status'))
        .then(() => {
          this.$message.success(err_msg)
          row.status = tobe
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.submiting = false
        })
    },
    submitCard(body) {
      const method = body.id ? updateVipCard : createVipCard
      const err_msg = body.id ? '修改成功' : '创建成功'
      this.submiting = true
      method(reBuildBody(body))
        .then(() => {
          this.$message.success(err_msg)
          this.editingVisible = false
          this.resetTable()
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.submiting = false
        })
    }
  }
}
</script>
