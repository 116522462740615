<template>
  <SoloBaseSelect
    :searchMethod="searchMethod"
    :modelValue="modelValue"
    @update:modelValue="updateEmit"
  />
</template>
<script>
import SoloBaseSelect from '@/components/selectors/solo-selector'
import { searchInternalUser } from '@/domains/user/api'

/**
 * 单选远程搜索框用例 邮箱搜wscn员工库
 * @param vModel string number object 若string、number为真需要手动指定options
 * @param searchMethod 编写搜索函数
 * @param keymap 指定keymap
 */

function searchMethod(query, ctx) {
  if (!/\S/.test(query)) return
  ctx.loading.value = true
  searchInternalUser({ email: query, limit: 20 })
    .then((data) => {
      if (data.items && data.items.length) {
        ctx.options.value = data.items.map((uu) => {
          return {
            label: uu.display_name || uu.email,
            value: uu.uid
          }
        })
        return
      }
      ctx.options.value = []
    })
    .finally(() => {
      ctx.loading.value = false
    })
}

export default {
  name: 'StaffSelect',
  components: { SoloBaseSelect },
  props: {
    modelValue: { type: [String, Number, Object], default: '' }
  },
  data() {
    return {}
  },
  methods: {
    searchMethod,
    updateEmit(ev) {
      this.$emit('update:modelValue', ev)
    }
  }
}
</script>